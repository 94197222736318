import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Box, Divider, Group, HoverCard, NavLink, Text } from '@mantine/core';

import { useAuth0 } from '@auth0/auth0-react';

import { topNavigationOptions } from 'layout/constants';
import { PATH_DASHBOARD } from 'shared/constants';

const TopNavigationRightAuthenticated: React.FC = () => {
  const { user } = useAuth0();
  const roles = user?.[`${process.env.REACT_APP_DOMAIN || ''}/roles`];
  const hasDashboardAccess = roles?.includes('verified');

  return (
    <HoverCard position="bottom-end" shadow="md" withArrow arrowSize={12} transition="scale" transitionDuration={200}>
      <HoverCard.Target>
        <Avatar radius="xl" size="md" src={user?.picture} alt={user?.name} />
      </HoverCard.Target>
      <HoverCard.Dropdown sx={{ width: 250 }}>
        <Group spacing="xs" className="top-navigation__right-side__name">
          <Avatar radius="xl" size="md" src={user?.picture} alt={user?.name} />
          <Box>
            <Text size="sm" weight={600}>
              {user?.email}
            </Text>
            <Text size="sm" color="gray.7">
              {user?.name}
            </Text>
          </Box>
        </Group>
        {Object.entries(topNavigationOptions).map(([path, { icon: Icon }]) => {
          if (path === PATH_DASHBOARD && !hasDashboardAccess) {
            return <div key={path} />;
          }

          return (
            <React.Fragment key={path}>
              <Divider my={5} />
              <NavLink
                className="top-navigation__right-side__dropdown-menu__option"
                label={topNavigationOptions[path].title}
                component={Link}
                to={path}
                icon={<Icon size={20} stroke={1.5} />}
              />
            </React.Fragment>
          );
        })}
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export default TopNavigationRightAuthenticated;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface SubscriptionLinkProps {
  data: any; // You can replace 'any' with the correct type if available from Ag-Grid
  value: string;
 
}

const SubscriptionLink: React.FC<SubscriptionLinkProps> = ({ data, value}) => {
  const { user: authenticatedUser } = useAuth0();
  const signatureId = value;
  const { deal_id: dealID } = data;
  const link = `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  }/o/users%2Fdocuments%2F${authenticatedUser?.sub}%2F${dealID}%2F${signatureId}.pdf?alt=media`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      View Document
    </a>
  );
};

export default SubscriptionLink;

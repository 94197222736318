import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Text, Group, Stack, Box, Title, NumberInput, Button, Alert, Modal, Textarea } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import DetailsContainer from 'components/Common/DetailsContainer/DetailsContainer';
import './DealsPage.scss';
import { formatDate, } from 'utils/datetime';
import { formatNumberWithCommas } from 'utils/currencySymbols';
import { dealsFields } from './utils';
import moment from 'moment';
import { PATH_ROOT } from 'shared/constants';
import { useAuth0 } from '@auth0/auth0-react';
import backendConfig from 'config/backend.config';
import { toast } from 'react-toastify';
import { fetchData } from 'utils/apiUtils';
import InvestmentInput from 'components/Common/InvestmentInput';

const InvestButton: React.FC<{ isUserVerified: boolean; user: any; onClick: () => void; isDisabled: boolean; isClosed: boolean }> = ({ isUserVerified, user, onClick, isDisabled, isClosed }) => {
    let investText = 'Invest';
  
    if (isClosed) {
      investText = 'Closed';
    } else if (!user) {
      investText = 'Login to invest';
    } else if (user && !isUserVerified) {
      investText = 'Verify Identity to Invest';
    } 
  
    return <Button radius="sm" onClick={onClick} disabled={isDisabled || isClosed}>{investText}</Button>;
  };

const InvestmentForm: React.FC<{ data: any; currency: string; isUserVerified: boolean; user: any; onInvestClick: (value: number) => void }> = ({ data, currency, isUserVerified, user, onInvestClick }) => {
    const { dealId } = useParams<{ dealId: string }>();
    const [investmentValue, setInvestmentValue] = useState<number | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [isPassed, setPassedMessage] = useState<boolean>(false);
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
  
    const dealStart = data.start_date; 
    const dealEnd = data.end_date;
    
    const minutesLeftToStart = moment(dealStart, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').startOf('day').diff(moment(), 'minute');
    const daysLeftToStart = moment(dealStart, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').startOf('day').diff(moment(), 'day');
    const minutesLeftToEnd = moment(dealEnd, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').startOf('day').diff(moment(), 'minute');
    const daysLeftToEnd = moment(dealEnd, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').startOf('day').diff(moment(), 'day');
  
    const hasStarted = minutesLeftToStart < 0;
    const hasEnded = minutesLeftToEnd < 0;
  
    const isDisabled = hasEnded || !hasStarted; // Disable if deal has ended or hasn't started
    const isClosed = hasEnded; // Deal is closed if it has ended
    
    const passDeal = async () => {
        try {
          const token = await getAccessTokenSilently();
          await fetchData({
            url: `${backendConfig.baseUrl}v1/subscriptions?deal_id=${dealId}&preference=OPT_OUT`,
            method: 'PUT',
            token,
            data: { reason: message }
         });
          toast.success('Thanks for your feedback');
          navigate(PATH_ROOT);
        } catch (error) {
          console.error('Failed to pass the deal:', error);
        } finally {
          setPassedMessage(false); // Close the modal regardless of success or failure
          setMessage(''); // Clear the message regardless of success or failure
        }
      };
  
    const handleInvestButtonClick = () => {
      if ((investmentValue === undefined || investmentValue < data.min_validation) && user && isUserVerified) {
        const minInvest = formatNumberWithCommas(data.min_investment); 
        setMessage(`Minimum investment amount is ${currency}${minInvest}`);
      } else {
        setMessage(undefined);
        onInvestClick(investmentValue || 0);
      }
    };

    const minInvest = formatNumberWithCommas(data.min_investment);
  
    return (
      <>
        <Card shadow="md" p="lg" radius="md">
          <Title order={3} weight={500}>
            Invest
          </Title>
          <Text fw={550} mt={10} color="dimmed" size="sm">
            Minimum investment <b>${minInvest}</b> by {formatDate(data.end_date, 'date')}
          </Text>
          <Stack spacing="xs" mt="md">
            <InvestmentInput 
              currency={currency}
              minInvestment={data.min_investment}
              investmentValue={investmentValue}
              setInvestmentValue={(value) => setInvestmentValue(value as number)}
              isDisabled={isDisabled}
            />
          </Stack>
          {message && !isPassed && (
            <Alert mt="md" color="red">
              {message}
            </Alert>
          )}
          <Box mt="md" className="deals-action">
            <InvestButton
              isUserVerified={isUserVerified}
              user={user}
              onClick={handleInvestButtonClick}
              isDisabled={isDisabled}
              isClosed={isClosed}
            />
            <Button radius="sm" variant="outline" color="gray" disabled={isDisabled || !isUserVerified || isClosed || !user} onClick={() => { 
               setMessage('');
               setPassedMessage(true)} 
              }>
              Pass
            </Button>
          </Box>
          <Stack spacing="xs" mt="md">
            <DetailsContainer className="sticky" fields={dealsFields} data={data} currencyCode={data.currency} />
          </Stack>
        </Card>
        <Modal
          opened={isPassed}
          onClose={() => setPassedMessage(false)}
          title={<Title order={4} weight={500}>Why would you like to pass the deal?</Title>}
        >
          <Box>
            <Text fz="md" mt={10} color="dimmed"> Help us understand your investment preferences by sharing your reasons for passing </Text>
            <Textarea
              mt="md"
              label=""
              value={message}
              minRows={5}
              placeholder="Share your reason for passing the deal"
              onChange={(event) => setMessage(event.currentTarget.value)}
            />  
          </Box>
          <Group grow mt="xl">
            <Button onClick={passDeal}>
              Submit
            </Button>
          </Group>
        </Modal>
      </>
    );
  };

  export default InvestmentForm;
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { MantineProvider, MantineTheme, Loader } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useAuth0 } from '@auth0/auth0-react';

import GoogleAnalyticsService from 'components/Common/GoogleAnalyticsService';
import NotFoundPage from 'pages/error/404/NotFoundPage';
import Homepage from 'pages/homepage/Homepage';
import Login from 'pages/login/Login';
import Logout from 'pages/logout/Logout';
import SettingsPageLayout from 'pages/settings/SettingsPageLayout';
import BasicSettingsPage from 'pages/settings/basic/BasicSettingsPage';
import IdentitySettingsPage from 'pages/settings/identity/IdentitySettingsPage';
import theme from './styles/theme';
import {
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_ROOT,
  PATH_SETTINGS,
  PATH_DASHBOARD,
  PATH_DEALS_PAGE,
  PATH_DEALS_COMPLETION
} from 'shared/constants';
import './index.scss';
import { setShouldConfirm } from 'pages/settings/SettingsSlice';
import { useAppDispatch } from 'store/hooks';
import PortfolioSettingsPage from 'pages/settings/portfolio/Portfolio';
import DealsPage from 'pages/DealsPage';
import DashboardPortfolioPage from 'pages/DashboardPortfolioPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InvestmentPage from 'pages/InvestmentPage';

const App = () => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  // Set up Google Analytics
  useEffect(() => {
    if (user) {
      GoogleAnalyticsService.setUserDetails({
        sub: user.sub || '',
        email: user.email || '',
        name: user.name || '',
      });
    }
  }, [user]);

  // Track page view on every URL change
  useEffect(() => {
    GoogleAnalyticsService.sendPageView(location.pathname + location.search);
  }, [location]);

  // Fetch user-related data
  useEffect(() => {
    const fetchShouldConfirmUser = async () => {
      const accessToken = await getAccessTokenSilently();
      const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL || '';
      const userId = user?.sub || '';

      if (!userId) return;

      try {
        const res = await fetch(`${baseUrl}v1/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
          },
        });

        const data = await res.json();
        if(data !== null) {
          dispatch(setShouldConfirm(false));
        } else {
          dispatch(setShouldConfirm(true));
        }
        // dispatch(setShouldConfirm(data ? false : true));
      } catch (error) {
        dispatch(setShouldConfirm(true));
      }
    };

    if (user) {
      fetchShouldConfirmUser();
    }
  }, [user, getAccessTokenSilently, dispatch]);

  // Show loading until Auth0 is done
  if (isLoading) {
    return (<div className="loader-container"> 
      <Loader />
       Loading...
    </div>);
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: location.pathname } });
    return null;
  }

  return (
    <MantineProvider theme={theme as unknown as MantineTheme} withCSSVariables withGlobalStyles withNormalizeCSS>
      <NotificationsProvider>
        <ToastContainer />
        <Routes>
          <Route path={PATH_ROOT} index element={<Homepage />} />
          <Route path={PATH_LOGIN} element={<Login />} />
          <Route path={PATH_LOGOUT} element={<Logout />} />
          <Route path={PATH_DEALS_COMPLETION} element={<InvestmentPage />} />
          <Route path={PATH_DEALS_PAGE} element={<DealsPage />} />
          <Route path={PATH_DASHBOARD} element={<DashboardPortfolioPage />} />
          <Route path={PATH_SETTINGS} element={<SettingsPageLayout />}>
            <Route index element={<Navigate replace to="basic" />} />
            <Route path="basic" element={<BasicSettingsPage />} />
            <Route path="identity" element={<IdentitySettingsPage />} />
            <Route path="entity" element={<BasicSettingsPage />} />
            <Route path="portfolio" element={<PortfolioSettingsPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default App;

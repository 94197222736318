import React, { useState, useMemo }  from 'react';
import { Radio, Title, Button, Text } from '@mantine/core';
import { useGetEntitiesQuery, useVerifyIdentyMutation } from 'services/invest/entities.service'; // Assuming this is the path for the API file
import { EntityStatus, EntityType } from 'shared/enums';
import { Entity } from 'models/invest/entity.api.model'
import { IconPlus } from '@tabler/icons';
import backendConfig from 'config/backend.config';
import { fetchData } from 'utils/apiUtils';
import { useSelector } from 'react-redux';

import RadioCard from 'components/Common/RadioCard/RadioCard';
import EntityModal from '../../../components/Common/Entity/components/EntityModal';
import IdenfyIframe from '../../Kyc/IdenfyIframe';
import NewInvestmentMessage from './NewInvestmentMessage';
import PersonaComponent from '../../Kyc/PersonaComponentNew';

let selectedEntity: Entity | undefined;
const RadioCardGroup = ({ value, setValue }: { value: string | undefined, setValue: (data: Entity | undefined) => void }) => {
    const token = useSelector((state: any) => state.auth0.token);
    const skip = !token;
  // Fetch entities, skip query if authentication isn't ready
    const { data: entities , error, isLoading, refetch } = useGetEntitiesQuery(undefined, { skip });

    const [opened, setOpened] = useState(false);
    const [mode, setMode] = useState<'new' | 'edit'>('new');
    const [editData, setEditData] = useState<Entity | null>(null); // Will be filled for "edit"
    const [verificationToken, setVerificationToken] = useState<{ session_token: string, inquiry_id: string } | null>(null);
    const [isIdenfyOpen, setIsIdenfyOpen] = useState<boolean>(false);
    const hasIndvisualEntity = useMemo(() => entities?.some(entity => entity.type === 'INDIVIDUAL'), [entities])

    const [ verifyIdenty ] = useVerifyIdentyMutation();

    const fetchVerificationToken = async (entity: Entity) => {
        // Mock API call to fetch verification token
        try {
          const data = await fetchData({
            url: `${backendConfig.baseUrl}v1/inquiries?entity_id=${entity.id}`,
            method: 'GET',
            token,
          });
          return data;
        } catch (err) {
          console.error('Error fetching token:', err);
          return null;
        }
      };
   
    const handleStatusClick  = async (entity: Entity) => {
        selectedEntity = entity;
        if (entity.status === 'NOT_VERIFIED') { 
            try {
                const idfyToken = await fetchVerificationToken(entity);
                if (idfyToken) {
                    setVerificationToken(idfyToken);
                    setIsIdenfyOpen(true);
                }
            } catch(e) {
                console.error(" Error: ", e);
            }
        }
        // setEditData(entity);
        // setMode('edit');
        // setOpened(true);
      };
  
      const handleIdenfySuccess = async (result: any) => {
          const payLoad = {
            entity_id: selectedEntity?.id || '',
            status: 'approved'
          };
        await verifyIdenty(payLoad); 
        setIsIdenfyOpen(false); // Close IDenfy after success
      };
    
      const handleIdenfyError = (err: any) => {
        console.error('IDenfy verification error:', err);
      };

      const handleIdenfyCloseModal = () => {
        setIsIdenfyOpen(false);
        setVerificationToken(null);
      }

      const handlePersonnaSuccess = () => {
        setIsIdenfyOpen(false);
        setVerificationToken(null);
        refetch();
      }

    const navigateToNewEntity = () => {
        setEditData(null);
        setMode('new');
        setOpened(true);
    }

    const setEntities = (id: string) => {
        const entity = entities?.find((data: Entity) => data.id === id);
        setValue(entity);
    }


    const idenfyUrl = selectedEntity 
        ? EntityType[selectedEntity.type as keyof typeof EntityType]?.indenfyBaseUrl || ''
        : '';
    
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error fetching entities </div>;

    return (
        <>
        <Radio.Group
            value={value}
            label={<Title order={4}>Who is investing?</Title>}
            onChange={setEntities}
            orientation="vertical"
            spacing="sm"
         >
        <>
            {entities?.map((entity: Entity) => {
            const statusValue = entity.status as keyof typeof EntityStatus;
            const status = EntityStatus[statusValue];

            const typeLabel = EntityType[entity.type as keyof typeof EntityType]?.label || 'Unknown type';

            return (
            <RadioCard
                key={entity.id}
                value={entity.id}
                title={entity.legal_name || entity.first_name || ''}
                description={typeLabel} // Pass the type label
                status={status?.label} // Pass the status label
                color={status?.color}
                statusClickDisabled={entity.status !== 'NOT_VERIFIED'}
                onStatusClick={() => handleStatusClick(entity)} // Pass the status value to retrieve URL
                isSelected={value === entity.id}
                onChange={setEntities}
            />
            );
        })}

        {!entities || !entities.length ? 
          <>
           <NewInvestmentMessage 
            onNewEntityClick={navigateToNewEntity}
           /> 
           <Text color="red"> <i> Note: </i>A valid investment entity is required for investing.</Text>
          </>:
          <>
            <Button leftIcon={<IconPlus />} variant="subtle" onClick={navigateToNewEntity}>
                New Investment Entity
            </Button>  
           {  !value && <Text color="red"> <i> Note: </i>A valid investment entity is required for investing.</Text> }
           </>
        }

         {opened && (
            <EntityModal
                opened={opened}
                setOpened={setOpened}
                mode={editData ? 'edit' : 'new'} // Determine mode based on the presence of editData
                initialData={editData} // Pass initial data for editing, empty for new
                disabledIndivisual = {hasIndvisualEntity || false}
            />
         )}
   </>
      </Radio.Group>

        {/* {isIdenfyOpen && verificationToken && (
            <IdenfyIframe
            opened={isIdenfyOpen}
            onSuccess={handleIdenfySuccess}
            onError={handleIdenfyError}
            onClose={handleIdenfyCloseModal}

            idenfyUrl={`${idenfyUrl}${verificationToken}`}
            />
        )} */}


        {isIdenfyOpen && verificationToken && (
            <PersonaComponent
                opened={isIdenfyOpen}
                inquiryId={verificationToken.inquiry_id}
                onComplete={handlePersonnaSuccess}
                onCloseModel={handleIdenfyCloseModal}
                sessionToken={verificationToken.session_token}
            />
        )}
      </>
    );
  };

  export default RadioCardGroup;